<template>
  <span>
    <sdPageHeader :title="`Welcome back, ${authUserName}!`" :subTitle="`Did you knew that ${today_conversion}% of your visitors participated in your games today?`">
      <template v-slot:buttons>
        <h3>Showing statistics for</h3>
        <div class="page-header-actions">
          <a-range-picker @change="dateChange"
                :default-value="initialDate()"
                :format="dateFormat"
                :allowClear="false"
          />
          <!-- <sdCalendarButton /> -->
        </div>
      </template>
    </sdPageHeader>
  </span>
  <div v-if="fetching" class="spin">
    <a-spin />
  </div>
  <Main v-else>
    <a-row :gutter="25">
      <a-col :xl="8" :md="12" :sm="12" :xs="24">
        <sdCards headless>
          <EChartCard>
            <div class="card-chunk">
              <CardBarChart2>
                <sdHeading as="h1">{{total_visitors}}</sdHeading>
                <span>Visitors</span>
                <!-- <p>
                  <span class="growth-upward"> <sdFeatherIcons type="arrow-up" /> 25% </span>
                  <span>Since last week</span>
                </p> -->
              </CardBarChart2>
            </div>
            <div class="card-chunk">
              <Chart
                class="growth"
                :height="180"
                :style="{
                  marginBottom: '-5',
                }"
                :labels="previousVisitorMonth"
                :datasets="[
                  {
                    data: previousVisitorValue,
                    backgroundColor: '#EFEFFE',
                    hoverBackgroundColor: '#5F63F2',
                    label: 'Visitor',
                    barPercentage: 1,
                  },
                ]"
                :options="chartOptions"
              />
            </div>
          </EChartCard>
        </sdCards>
      </a-col>
      <a-col :xl="8" :md="12" :sm="12" :xs="24">
        <sdCards headless>
          <EChartCard>
            <div class="card-chunk">
              <CardBarChart2>
                <sdHeading as="h1">{{total_participants}}</sdHeading>
                <span>Participants</span>
                <!-- <p>
                  <span class="growth-downward"> <sdFeatherIcons type="arrow-down" /> 25% </span>
                  <span>Since last week</span>
                </p> -->
              </CardBarChart2>
            </div>
            <div class="card-chunk">
              <Chart
                class="downward"
                :height="180"
                :style="{
                  marginBottom: '-5',
                }"
                :labels="previousParticipantsMonth"
                :datasets="[
                  {
                    data: previousParticipantsValue,
                    backgroundColor: '#FFF0F6',
                    hoverBackgroundColor: '#FF69A5',
                    label: 'Participant',
                    barPercentage: 1,
                  },
                ]"
                :options="chartOptions"
              />
            </div>
          </EChartCard>
        </sdCards>
      </a-col>
      <a-col :xl="8" :md="12" :sm="12" :xs="24">
        <sdCards headless>
          <EChartCard>
            <div class="card-chunk">
              <CardBarChart2>
                <sdHeading as="h1">{{conversion_rate}}%</sdHeading>
                <span>Conversion Rate</span>
                <!-- <p>
                  <span class="growth-upward"> <sdFeatherIcons type="arrow-up" /> 25% </span>
                  <span>Since last week</span>
                </p> -->
              </CardBarChart2>
            </div>
            <div class="card-chunk">
              <Chart
                class="upward"
                :height="180"
                :style="{
                  marginBottom: '-5',
                }"
                :labels="previousConversionMonth"
                :datasets="[
                  {
                    data: previousConversionValue,
                    backgroundColor: '#E8FAF4',
                    hoverBackgroundColor: '#20C997',
                    label: 'Conversation Rate',
                    barPercentage: 1,
                  },
                ]"
                :options="chartOptions"
              />
            </div>
          </EChartCard>
        </sdCards>
      </a-col>
    </a-row>
    <a-row :gutter="25">
      <a-col :xxl="24" :xs="24">
        <Suspense>
          <RevenueWrapper>
            <sdCards :title="'Visitors and participants'.toUpperCase()">
              <div class="performance-lineChart">
                <ul>
                  <li v-for="(item, key) in dataCollection" :key="key + 1" class="custom-label">
                    <strong :class="item.amountClass">{{ item.amount }}</strong>
                    <div>
                      <span
                        :style="{
                          backgroundColor: item.borderColor,
                        }"
                      />
                      {{ item.label }}
                    </div>
                  </li>
                </ul>
                <sdChartContainer class="parentContainer">
                  <Chart
                    class="perform"
                    type="line"
                    id="perform"
                    :labels="dataCollectionLabels"
                    :datasets="dataCollection"
                    :options="visNParChartOptions"
                    :height="visNParChartHeight"
                  />
                </sdChartContainer>
              </div>
            </sdCards>
          </RevenueWrapper>
        </Suspense>
      </a-col>
    </a-row>
    <a-row :gutter="25">
      <a-col :xxl="24" :xs="24">
        <sdCards :bodyStyle="{padding: '0 !important'}" :title="'Most performing campaigns'.toUpperCase()">
          <a-table
            rowKey="id"
            :columns="mostVisitedCampaignFields"
            :dataSource="mostVisitedCampaign"
            :pagination="false"
          >
            <template #name="{record}">
              <a @click="navigateToDetails(record)">{{record.name}}</a>
            </template>
            <template #conversion_rate="{record}">
              {{record.conversion_rate}}%
            </template>
          </a-table>
        </sdCards>
      </a-col>
    </a-row>
    <CardChartStyle style="margin-top: 20px;">
      <a-row :gutter="25">
        <a-col :xl="8" :md="12" :xs="24">
          <DoughnutChart
            title="Devices"
            totalTitle="Total Devices"
            displayClass="device"
            :chartData="deviceData"
            :colors="deviceColors"
          />
        </a-col>
        <a-col :xl="8" :md="12" :xs="24">
          <DoughnutChart
            title="Operating Systems"
            totalTitle="Total OS"
            displayClass="os"
            :chartData="osData"
            :colors="osColors"
          />
        </a-col>
        <a-col :xl="8" :md="12" :xs="24">
          <DoughnutChart
            title="Browsers"
            totalTitle="Total Browsers"
            displayClass="browser"
            :chartData="browserData"
            :colors="browserColor"
          />
        </a-col>
      </a-row>
    </CardChartStyle>
  </Main>
</template>
<script>
import _ from "lodash";
import moment from "moment";

import { CardBarChart2, EChartCard, CardChartStyle, RevenueWrapper } from './style';
import DoughnutChart from '../../../components/charts/DoughnutChart/DoughnutChart.vue';
import Chart from "../../../components/utilities/chartjs.vue";
import { Main } from '../../styled';

import { customTooltips, chartLinearGradient } from "../../../components/utilities/utilities";
import Notif from "@/services/Notification";
import { API } from "@/services/api";

const chartOptions = {
  legend: {
    display: false,
    labels: {
      display: false,
    },
  },
  scales: {
    yAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    ],
  },
};
const visNParChartOptions = {
  maintainAspectRatio: true,
  elements: {
    z: 9999,
  },
  legend: {
    display: false,
    position: 'bottom',
    align: 'start',
    labels: {
      boxWidth: 6,
      display: false,
      usePointStyle: true,
    },
  },
  hover: {
    mode: 'index',
    intersect: false,
  },
  tooltips: {
    mode: 'label',
    intersect: false,
    backgroundColor: '#ffffff',
    position: 'average',
    enabled: false,
    custom: customTooltips,
    callbacks: {
      // title() {
      //   return `Total Revenue`;
      // },
      label(t, d) {
        const { yLabel, datasetIndex } = t;
        return `<span class="chart-data">${yLabel}</span> <span class="data-label">${d.datasets[datasetIndex].label}</span>`;
      },
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          color: '#e5e9f2',
          borderDash: [3, 3],
          zeroLineColor: '#e5e9f2',
          zeroLineWidth: 1,
          zeroLineBorderDash: [3, 3],
        },
        ticks: {
          beginAtZero: true,
          fontSize: 13,
          fontColor: '#182b49',
          suggestedMin: 50,
          stepSize: 20,
          callback(label) {
            return `${label}`;
          },
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: true,
          zeroLineWidth: 2,
          zeroLineColor: 'transparent',
          color: 'transparent',
          z: 1,
          tickMarkLength: 0,
        },
        ticks: {
          padding: 10,
        },
      },
    ],
  },
};

export default {
  name: 'Dashboard',
  components: {
    Main,
    Chart,
    CardBarChart2,
    EChartCard,
    CardChartStyle,
    DoughnutChart,
    RevenueWrapper
  },
  computed: {
    authUserName: function() {
      String.prototype.capitalize = function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
      }
      const { first_name = "", last_name = "" } = this.$store?.getters?.authUser ?? {};
      const name = `${first_name}${first_name && last_name ? ' ' : ''}${last_name}`;
      return name.capitalize();
    },
    authUserAvatar: function() {
      return this.$store?.getters?.authUser?.avatar ?? "";
    },
    initial: function() {
      return this.$store?.getters?.initial;
    }
  },
  data() {
    let startDate = new Date();
    //startDate.setDate(startDate.getDate() - 6);
    let endDate = new Date();
    return {
      dateRange: {startDate, endDate},
      dateFormat : 'YYYY-MM-DD',
      maxDate: endDate,
      conversion_rate: 0,
      total_participants: 0,
      total_visitors: 0,
      previousParticipantsMonth: [],
      previousParticipantsValue: [],
      previousVisitorMonth: [],
      previousVisitorValue: [],
      previousConversionMonth: [],
      previousConversionValue: [],
      today_conversion: 0,
      fetching: true,
      dataCollection: [],
      dataCollectionLabels: [],
      chartOptions,
      visNParChartOptions,
      visNParChartHeight: window.innerWidth <= 575 ? 100 : 60,
      deviceData: [],
      deviceColors: ['#20C997', '#5F63F2', '#FA8B0C'],
      osData: [],
      osColors: ['#560bd0', '#007bff', '#00cccc', '#cbe0e3', '#74de00'],
      browserData: [],
      browserColor: ['#6050DC', '#ff9900', '#ED2400', '#DEDEDE', '#54D727', '#E8E8E8', '#F01CFE', '#A5C1DC', '#193F5C'],
      mostVisitedCampaign: [],
      mostVisitedCampaignFields: [
        { title: 'Id', dataIndex: 'id', key: 'id'},
        { title: 'Name', dataIndex: 'name', key: 'name',
          slots: { customRender: 'name' },
        },
        { title: 'Conversion rate', dataIndex: 'conversion_rate', key: 'conversion_rate',
          slots: { customRender: 'conversion_rate' },
        },
        { title: 'Participant', dataIndex: 'sub_count', key: 'sub_count' },
      ],
    };
  },
  methods: {
    async fillData() {
      try {
        const params = {
          start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
          end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
        };

        const dataRes = await API.getDashboardData({ params }, 'portal');
        const { status, message, data } = dataRes?.data;
        if (status) {
          const {
            units = [],
            os_data = [],
            conversion,
            visitors,
            participants,
            today_conversion,
            graph_data = [],
            browser_data = [],
            most_visited_campaign = [],
          } = data;

          const {
            conversion_rate,
            previous_conversion,
          } = conversion;

          const {
            total_visitors,
            previous_visitors,
          } = visitors;

          const {
            total_participants,
            previous_participants,
          } = participants;

          this.previousParticipantsMonth = _.map(Object.values(previous_participants), (v) => v.label);
          this.previousParticipantsValue = _.map(Object.values(previous_participants), (v) => v.value);
          this.previousVisitorMonth = _.map(Object.values(previous_visitors), (v) => v.label);
          this.previousVisitorValue = _.map(Object.values(previous_visitors), (v) => v.value);
          this.previousConversionMonth = _.map(Object.values(previous_conversion), (v) => v.label);
          this.previousConversionValue = _.map(Object.values(previous_conversion), (v) => v.value);
  
          this.deviceData = units;
          this.osData = os_data;
          this.browserData = browser_data;
          this.mostVisitedCampaign = most_visited_campaign;

          this.conversion_rate = conversion_rate;
          this.total_participants = total_participants;
          this.total_visitors = total_visitors;
          this.today_conversion = today_conversion;
          this.dataCollectionLabels = _.map(graph_data, (g) => g.label);
          this.dataCollection = [
          {
            data: _.map(graph_data, (g) => g.participants),
            borderColor: '#5F63F2',
            borderWidth: 4,
            fill: true,
            backgroundColor: () => this.chartMethods('perform', ['#5F63F230', '#ffffff05']),
            label: 'Participants',
            pointStyle: 'circle',
            pointRadius: '0',
            hoverRadius: '9',
            pointBorderColor: '#fff',
            pointBackgroundColor: '#5F63F2',
            hoverBorderWidth: 5,
            amount: total_participants,
            amountClass: 'current-amount',
          },
          {
            data: _.map(graph_data, (g) => g.visitors),
            borderColor: '#C6D0DC',
            borderWidth: 2,
            fill: false,
            backgroundColor: '#00173750',
            label: 'Visitors',
            // borderDash: [3, 3],
            pointStyle: 'circle',
            pointRadius: '0',
            hoverRadius: '0',
            amount: total_visitors,
            amountClass: 'prev-amount',
          },
        ];

        } else if (!status && message) {
          this.$router.go(-1);
          Notif.show(message, {type: "error", title: "Error"});
        }
      } catch (error) {
        if (error?.message) {
          Notif.show(error.message, {type: "error", title: "Error"});
        }
      } finally {
        this.fetching = false;
      }
    },
    chartMethods(elementId, color) {
      return chartLinearGradient(document.querySelector(`.${elementId}`), 300, {
        start: color[0],
        end: color[1],
      });
    },
    dateChange(_date, dateString) {
      this.dateRange = {
        startDate: dateString[0],
        endDate: dateString[1],
      };
      this.fillData();
    },
    initialDate() {
      return [moment(this.dateRange.startDate, this.dateFormat), moment(this.dateRange.endDate,this.dateFormat)]
    },
    navigateToDetails(campaign) {
      const {campaign_encrypt_id = ""} = campaign;
      if (campaign_encrypt_id) {
        this.$router.push(`campaigns/details/${campaign_encrypt_id}`);
      }
    }
  },
  created() {
    this.fillData();
  }
};
</script>
<style>
span .ant-page-header-heading-title {
   text-transform: none !important;
   font-size: 30px;
}
</style>
